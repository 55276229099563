import React from 'react';
import './Sections.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const WorkSample = () => {
  return (
    <section id="worksample" className="projects section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header iranfont">
          <h2>نمونه کارهای تکسامش</h2>
        </div>

        <div
          className="row gy-4 portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className={'col-lg-3 col-md-6 col-sm-6  text-center mx-auto'}>
            {/* <div className="portfolio-content h-100"> */}
            <LazyLoadImage
              src="https://api.taksamesh.ir/uploads/5149a89e-3d67-4dcc-b467-0ef073a8df6a.png"
              className="d-block mx-auto"
              alt=""
              effect="blur"
              style={{
                maxHeight: '300px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '5px',
              }}
            />
            {/* </div> */}
          </div>
          <div className={'col-lg-3 col-md-6 col-sm-6  text-center mx-auto'}>
            {/* <div className="portfolio-content h-100"> */}
            <LazyLoadImage
              src="https://api.taksamesh.ir/uploads/6d5e52c1-6e3d-41f1-9680-bfc78b2350e1.png"
              className="d-block mx-auto"
              alt=""
              effect="blur"
              style={{
                maxHeight: '300px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '5px',
              }}
            />
            {/* </div> */}
          </div>
          <div className={'col-lg-3 col-md-6 col-sm-6  text-center mx-auto'}>
            {/* <div className="portfolio-content h-100"> */}
            <LazyLoadImage
              src="https://api.taksamesh.ir/uploads/c6214e82-c71d-45c1-bf8a-fabdfc74767b.jpeg"
              className="d-block mx-auto"
              alt=""
              effect="blur"
              style={{
                maxHeight: '300px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '5px',
              }}
            />
            {/* </div> */}
          </div>
          <div className={'col-lg-3 col-md-6 col-sm-6 text-center mx-auto'}>
            {/* <div className="portfolio-content h-100"> */}
            <LazyLoadImage
              src="https://api.taksamesh.ir/uploads/381c224c-50c2-40e6-a672-27d9f21cc8b5.png"
              className="d-block mx-auto"
              alt=""
              effect="blur"
              style={{
                maxHeight: '300px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '5px',
              }}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkSample;
